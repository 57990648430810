import { Store } from "./Store.js"
import { Modal_1__1 } from "../Components/Modal_1__1.js"
import { Install_1__1 } from "../Components/Install_1__1.js"
import { Lector_1__1 } from "../Components/Lector_1__1.js"
import { KeyboardShortcuts_1__1 } from "../Components/KeyboardShortcuts_1__1.js"
import { Languages } from "../Components/Languages.js"
import { Switch } from "../Components/Switch.js"
import { NavigationData_1__1 } from "../Components/NavigationData_1__1.js"
import { Tabs } from "../Components/Tabs.js"
import { Cookies } from "../Components/Cookies.js"
import { Header } from "../Components/Header.js"
import { Message } from "../Components/Message.js"

export class Application
{
    app = {}
    store = new Store()

    constructor()
    {
        this.app.modal = new Modal_1__1()
        this.app.install = new Install_1__1()
        this.app.lector = new Lector_1__1({app: this.app})
        this.app.keyboardShortcuts = new KeyboardShortcuts_1__1({app: this.app})
        this.app.languages = new Languages()
        this.app.Switch = new Switch()
        this.app.navigationData = new NavigationData_1__1()
        this.app.Tabs = new Tabs()
        this.app.Cookies = new Cookies()
        this.app.Header = new Header()
        this.app.Message = new Message()
    }

    run()
    {
        this.app.modal.watch()
        this.app.install.run()
        this.app.lector.run()
        this.app.lector.watch()
        this.app.keyboardShortcuts.watch()
        this.app.languages.run()
        this.app.Switch.watch()
        this.app.navigationData.run()
        this.app.navigationData.watch()
        this.app.Tabs.run()
        this.app.Tabs.watch()
        this.app.Cookies.run()
        this.app.Cookies.watch()
        this.app.Header.watch()
        this.app.Message.watch()
        this.app.Message.run()
    }
}
