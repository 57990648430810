import marquee from "vanilla-marquee"

export class Message
{
	message = document.querySelector('[data-s~="Message"]')
    buttonHide = document.querySelector('[data-s~="Message:hide"]')
	buttonPlay = document.querySelector('[data-s~="Message:play"]')
	buttonStop = document.querySelector('[data-s~="Message:stop"]')
	text = document.querySelector('[data-s~="Message:text"]')

    run()
    {
        this.message && (this.marque = new marquee(this.text, {
            speed: 100,
            pauseOnHover: true,
        }))
    }

    play()
    {
        this.buttonPlay.style.display = null
		this.buttonStop.style.display = null
		this.marque.resume()
    }

    stop()
    {
        this.buttonPlay.style.display = "flex"
		this.buttonStop.style.display = "none"
		this.marque.pause()
    }

    hide()
    {
        this.message.style.display = "none"
    }

    watch()
    {
        this.buttonPlay && this.buttonPlay.addEventListener("click", () => {
            this.play()
        })
        this.buttonStop && this.buttonStop.addEventListener("click", () => {
            this.stop()
        })
        this.buttonHide && this.buttonHide.addEventListener("click", () => {
            this.hide()
        })
    }
}
